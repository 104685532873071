<template>
  <div class="mt-4" v-if="canVerb(resource, 'show')">
    <b-overlay center :show="isLoading" rounded="sm">
      <b-card header="true" header-tag="header" class="mt-2" v-if="!isLoading">
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0 d-inline-block align-middle">
            <b>Dati di Agenzia</b>
          </p>
        </div>
        <table>
          <tr>
            <th>Azioni</th>
            <th>
              {{
                getDictionary("attribute_CNAM_value", "agency_setup"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_CREG_value", "agency_setup"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_NINO_value", "agency_setup"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_ADDR_value", "correspondence"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_PHON_value", "address_book"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_MAIL_value", "address_book"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_SMSFROM_value", "address_book"),
              }}
            </th>
            <th>
              {{
                getDictionary("attribute_SIGNMAIL_value", "address_book"),
              }}
            </th>
          </tr>
          <tr>
            <td>
              <div class="action-buttons">
                <b-button
                  v-if="canVerb(resource, 'edit')"
                  size="sm"
                  variant="lisaweb"
                  @click="onEdit"
                  class="mt-1 mr-1"
                  title="Modifica"
                >
                  <b-icon icon="pencil-square"></b-icon>
                </b-button>
              </div>
            </td>
            <td>{{ details.attributables.CNAM }}</td>
            <td>{{ details.attributables.CREG }}</td>
            <td>{{ details.attributables.NINO }}</td>
            <td>
              {{
                toRelationString(
                  "correspondences",
                  details["correspondences"],
                  ["ADDR"],
                  [0]
                )
              }}
            </td>
            <td>
              {{
                toRelationString(
                  "address_books",
                  details["address_books"],
                  ["PHON"],
                  [0]
                )
              }}
            </td>
            <td>
              {{
                toRelationString(
                  "address_books",
                  details["address_books"],
                  ["MAIL"],
                  [0]
                )
              }}
            </td>
            <td>
              {{
                toRelationString(
                  "address_books",
                  details["address_books"],
                  ["SMSFROM"],
                  [0]
                )
              }}
            </td>
            <td>
              {{
                toRelationString(
                  "address_books",
                  details["address_books"],
                  ["MAILFROM"],
                  [0]
                )
              }}
            </td>
          </tr>
        </table>
      </b-card>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters } from "vuex";
import { toRelationString } from "@/utils/transforms";
import { getDictionary } from "@/utils/dictionary";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [FormMixin, ShowMixin, StorageGetterMixin],
  data() {
    return {
      repository: "agency_setup",
      resource: "agency_setups",
      isLoading: false,
      details: [],
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    toRelationString,
    getDictionary,
    onEdit() {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${this.details.id}`, item: this.details },
      });
    },
  },
  created() {
    this.isLoading = true;
    let queryString = `relations/byAddressBook/byCorrespondence/byAgencyContact`;
    this.fetchShowForm(this.repository, this.settings.id, queryString)
      .then((response) => {
        this.details = response.data.data;
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
      });
    /* */
    // debugger;
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("councils"))
      // this.getResources("auth/councils");
      // resources.push({
      //   path: path,
      //   value: "councils",
      // });
      resources.push(
        this.$store
          .dispatch(`${path}councils`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    if (!this.hasLoadedResource("provinces"))
      // this.getResources("auth/provinces");
      // resources.push({
      //   path: path,
      //   value: "provinces",
      // });
      resources.push(
        this.$store
          .dispatch(`${path}provinces`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    if (!this.hasLoadedResource("countries")) {
      resources.push(
        this.$store
          .dispatch(`${path}countries`)
          .then(() => {
            // const defaultCountryId = this.getCountries().find(
            //   (country) => country.text == this.defaultCountryName
            // ).value;
            // this.opt_provinces = this.getProvinces()(defaultCountryId);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
        // .finally(() => {
        //   this.unloadMsg();
        //   this.isLoading = false;
        // })
      );
    }
    // else {
    //   const defaultCountryId = this.countries().find(
    //     (country) => country.text == this.defaultCountryName
    //   ).value;
    //   this.opt_provinces = this.provinces()(defaultCountryId);
    // }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          // const defaultCountryId = this.countries().find(
          //   (country) => country.text == this.defaultCountryName
          // ).value;
          // this.opt_provinces = this.provinces()(defaultCountryId);
          // this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
          // this.$emit("storageLoading", false);
        });
    } else {
      this.isLoading = false;
      // this.$emit("storageLoading", false);
    }
    /* */
  },
  computed: {
    ...mapGetters("auth", ["settings"]),
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
